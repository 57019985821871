import React from 'react'
import styled from 'styled-components'

const StColumn = styled.div`
  /* display: flex; */
  /* flex-flow: column nowrap; */
  max-width: 1024px;
  margin: auto;
  /* align-items: center; */
  /* justify-content: flex-start; */
  padding: ${({theme}) => theme.headerHeightBig} 0px 200px 0px;
  @media (max-width: 1100px) {
    max-width: 95vw;
  }
`

const Column = ({children, id}) => <StColumn id={id}>{children}</StColumn> 

export default Column