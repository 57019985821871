import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  H1,
  Hr,
  P,
  Ul,
  H2,
  BlockEl,
  Block280,
  FullLengthFlexBox,
} from "../components/layout/Styles";
import Form from "../components/form";
import { MdChatBubbleOutline, MdMailOutline } from "react-icons/md";
import { FiPhone } from "react-icons/fi";

const Box = styled.div`
  min-width: 280px;
  max-width: ${({ maxWidth }) => maxWidth || "600px"};
  margin: auto;
`;
const StP = styled(P)`
  /* text-align: center; */
  color: darkgrey;
  line-height: 1.5em;
  margin-left: 3em;
  /* max-width: 80%; */
`;
const FBox = styled(FullLengthFlexBox)`
  margin: 0px;
  flex-flow: row nowrap;
  align-items: flex-start;
`;

const SixthPage = () => (
  <Layout>
    <SEO title="Contact Us" />
    <H1>Talk To Us</H1>
    <Hr style={{ backgroundColor: "salmon" }} />
    {/* <Hr style={{ backgroundColor: "salmon" }} /> */}
    <Box>
      <FBox>
        <MdChatBubbleOutline size="10em" color="darkgrey" />
        <StP>
          We would love to hear from you. If you have any questions, concerns,
          comments or suggestions, send us a message and we promise to respond
          within a couple of hours.
        </StP>
      </FBox>
      <Form />
    </Box>
    <FBox
      style={{ maxWidth: "400px", justifyContent: "center", margin: "auto" }}
    >
      <h4 style={{ color: "darkgrey" }}>Call us &nbsp; </h4>
      <FiPhone size="1.3em" color="darkgrey" style={{ display: "block" }} />
      <h4 style={{ color: "darkgrey" }}>- (+27) 41 008 5010</h4>
    </FBox>
    <FBox
      style={{ maxWidth: "400px", justifyContent: "center", margin: "auto" }}
    >
      <h4 style={{ color: "darkgrey" }}>Email us &nbsp; </h4>
      <MdMailOutline
        size="1.3em"
        color="darkgrey"
        style={{ display: "block" }}
      />
      <h4 style={{ color: "darkgrey" }}>- Use the contact form above.</h4>
    </FBox>
  </Layout>
);

export default SixthPage;
