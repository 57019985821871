import styled, { css, keyframes } from "styled-components"
import React, { useState, useContext } from "react"
import Burger from "./burger"
import { Link } from "gatsby"
import { RootContext } from "../rootElement"
import {FaPowerOff} from "react-icons/fa"

export const StyledMenu = styled.nav`
  display: flex;
  justify-content: center;
  text-align: left;
  /* padding: 2rem; */
  transition: all 0.3s ease-in-out;
  @media (min-width: ${({ theme }) => theme.mobileMenu}) {
    height: 100%;
    align-items: stretch;
  }
  @media (max-width: ${({ theme }) => theme.mobileMenu}) {
    width: 85%;
    /* background: transparent; */
    background: ${({ theme }) => theme.primaryLight1};
    flex-direction: column;
    position: absolute;
    height: 100vh;
    top: 0;
    right: 0;
    border-radius: 150px 0px 0px 0px / 100% 0px 0px 0px;
    overflow: hidden;
    ${({ open }) =>
      !open
        ? css`
            transform: translateX(100%);
          `
        : css`box-shadow: -2px 0px 11px -4px rgba(0,0,0,0.75);`}
  }
`

const opacityFadeIn = keyframes`
  0% {
    opacity: 0
  }
  100% {
    opacity: 0.6
  }
`

const blink = keyframes`
  0% {
    opacity: 1;
  }
  20% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
`


const OpenDimBg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  animation: ${opacityFadeIn} 0.2s ease-out forwards;
`

const StLink = styled(Link).attrs({
  activeClassName: "active"
})`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  color: ${({highlite}) => highlite? "white": "grey"};
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
  border-left: 1px solid lightgrey;
  @media (min-width: ${({ theme }) => theme.mobileMenu}) {
    /* border-right: 1px solid lightgrey; */
    ${({highlite}) => highlite? css`
      /* background-color: teal; */
      &::before {
        content: "";
        position: absolute;
        left: 2%;
        top: calc(0.25 * ${({theme}) => theme.headerHeightBig} - 5px);
        width: 96%;
        height: calc(0.5 * ${({theme}) => theme.headerHeightBig});
        background-color: teal;
        border-radius: 10px;
        z-index: -10;
        animation: ${blink} 1.33s ease-out infinite;
      }
    `
      : null}
    &:hover, &.active {
      /* box-sizing: border-box; */
      padding: 0px 10px;
    }
    &:hover {
      border-bottom: 5px solid ${({ theme }) => theme.primaryHover};
    }
    &.active {
      border-bottom: 5px solid ${({ theme }) => theme.babyBlue};
    }
    /* &:last-child {
      border-right: none;
    } */
  }
  /* color: ${({ theme }) => theme.primaryDark}; */
  transition: color 0.3s linear;
  &:hover {
    color: ${({ theme }) => theme.primaryHover};
    /* text-decoration: underline; */
  }
  @media (max-width: ${({ theme }) => theme.mobileMenu}) {
    font-size: 1.5rem;
    text-align: center;
    padding: 20px 0px;
    border-bottom: 1px solid lightgrey;
    &:first-child {
      border-top: 1px solid lightgrey;
    }
  }
`

const Nav = () => {
  const [navOpen, setNavOpen] = useState(false)
  const {navItems} = useContext(RootContext)

  return (
    <>
      {navOpen && <OpenDimBg onClick={() => setNavOpen(false)} />}
      <StyledMenu open={navOpen}>
        {navItems.map((item, idx) => {
          return (
            <StLink to={item.link} key={`nav-${idx}`} highlite={item.label === "Start App"}>
              {item.label === "Start App" && <React.Fragment><FaPowerOff/> &nbsp;</React.Fragment>}
              {item.label}
            </StLink>
          )
        })}
      </StyledMenu>
      <Burger
        clickHandler={() => setNavOpen(navOpen => !navOpen)}
        open={navOpen}
      />
    </>
  )
}
export default Nav
