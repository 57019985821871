import React, {useContext} from "react"
import { Link } from "gatsby"
import styled from "styled-components"
// import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"
import { RootContext } from "../rootElement"

const TitleCont = styled(Link)`
  display: block;
  width: 200px;
  cursor: pointer;
`

const TitleComp = () => {

  const {title, logo} = useContext(RootContext)

  return (
    <TitleCont to="/">
      <GatsbyImage image={logo} alt={title} title={title}/>
    </TitleCont>
  )
}

export default TitleComp
