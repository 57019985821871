import React from "react"
import styled from "styled-components"
import TitleComp from "../nav/title"
import Nav from "../nav/nav"

export const HeaderCont = styled.header`
  width: 100vw;
  /* background-color: ${({theme}) => theme.primaryLight2}; */
  background-color: rgba(255,255,255, 0.95);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  height: ${({theme}) => theme.headerHeightBig};
  top: 0;
  left: 0;
  padding: 0px 30px;
  border-bottom: 1px solid lightgrey;
  & a {
    text-decoration: none;
  }
`

const Header = () => (
    <HeaderCont>
      <TitleComp/>
      <Nav/>
    </HeaderCont>
)

export default Header