import React, { createContext } from "react"
import Header from "./layout/header"
import Column from "./layout/bodyColumn"
import AnimPageContainer from "./layout/animPageCont"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <AnimPageContainer>
        <Column id="start">{children}</Column>
      </AnimPageContainer>
      <Header />
    </>
  )
}

export default Layout
