import styled, {keyframes} from 'styled-components'

const puffIn = keyframes`
  0% {
    transform: scale(0.8);
    opacity: 0;
    }
`

const Container = styled.div`
  width: 100%;
  animation: ${puffIn} 0.5s ease-out both;
`

export default Container;