import styled, {keyframes} from "styled-components"
import React, { useState } from "react"
// import {
//   FaUser,
//   FaEnvelope,
//   FaPenAlt,
//   FaMobileAlt
// } from "react-icons/fa"
import { MdMailOutline, MdClear } from "react-icons/md"
import { FiUser } from "react-icons/fi"
import { GiPhone, GiFountainPen } from "react-icons/gi"
import {H2} from "../components/layout/Styles"
import { Btn } from './layout/ui';
import { bounceOutRight } from './layout/animista';

const StForm = styled.form`
  display: block;
  width: 100%;
  border-radius: 15px;
  background-color: #f5f5f5;
  padding: 10px 30px 30px;
`

const Formfield = styled.div`
  width: 100%;
  position: relative;
`

const TextInput = styled.input.attrs(({ type }) => ({
  type: type,
}))`
  display: block;
  margin: 15px auto;
  width: 100%;
  border-width: 0px;
  /* border: 1px solid white; */
  /* border-color: white; */
  border-radius: 5px;
  outline: none;
  box-sizing: border-box;
  background-color: white;
  padding-left: 2em;
  &:focus {
    background-color: rgba(255, 140, 105, 0.1);
    /* border-width: 0px 0px 5px;
    border-color: salmon; */
  }
  transition: all 0.2s ease-out;
`

const iconStyle = {
  display: "block",
  position: "absolute",
  left: "0.5em",
  top: "0.25em",
  color: "darkgrey",
}
const clearBtnStyle = {
  display: "block",
  position: "absolute",
  right: "0.5em",
  top: "0.25em",
  color: "darkgrey",
  border: "none",
  backgroundColor: "transparent"
}

const FormContent = ({inputHandler, formData, clearField}) => (
  <>
    <H2 style={{textAlign: "center", color: "salmon"}}>Contact Form</H2>
    <Formfield>
      <TextInput
        placeholder="Your Name"
        id="name"
        type="text"
        aria-label="Name" 
        onChange={inputHandler}
        value={formData.name}
      />
      <FiUser style={iconStyle} />
      {formData.name && <MdClear style={clearBtnStyle} onClick={() => clearField("name")}/>}
    </Formfield>
    <Formfield>
      <TextInput 
      placeholder="Your Email" 
      id="email"
      type="email" 
      aria-label="Email" 
      onChange={inputHandler}
      value={formData.email}
      />
      <MdMailOutline style={iconStyle} />
      {formData.email && <MdClear style={clearBtnStyle} onClick={() => clearField("email")}/>}
    </Formfield>
    <Formfield>
      <TextInput 
      placeholder="Phone Number" 
      id="phone"
      type="tel" 
      aria-label="Phone" 
      onChange={inputHandler}
      value={formData.phone}
      />
      <GiPhone style={iconStyle} />
      {formData.phone && <MdClear style={clearBtnStyle} onClick={() => clearField("phone")}/>}
    </Formfield>
    <Formfield>
      <TextInput
        as="textarea"
        id="message"
        style={{ height: "8em" }}
        placeholder="Your Message"
        aria-label="User Message"
        onChange={inputHandler}
        value={formData.message}
      />
      <GiFountainPen style={iconStyle} />
      {formData.message && <MdClear style={clearBtnStyle} onClick={() => clearField("message")}/>}
    </Formfield>
    <Btn style={{margin: "auto"}} type="submit">Submit</Btn>
    {/* <button type="button" onClick={() => console.log(formData)}>log</button> */}
  </>
)


const SendingAnimation = styled(MdMailOutline)`
  animation: ${bounceOutRight} 1.5s both infinite;
`

const Form = () => {

  const initialState = {
    name: "",
    email: "",
    phone: "",
    message: ""
  }

  const [formData, setFormData] = useState(initialState)
  const [formStatus, setFormStatus] = useState("unSent")

  const inputHandler = e => {
    const {id, value} = e.target;
    setFormData(prevData => ({...prevData, [id]: value}))
  }
  const clearField = field => {
    setFormData(prevData => ({...prevData, [field]: ""}))
    document.getElementById(field).focus();
  }

  const submitHandler = e => {
    e.preventDefault();
    console.log(formData);
    setFormStatus("sending");
    setTimeout(() => setFormStatus("sent"), 3000)
    setFormData(initialState);
  }

  return (
    <StForm onSubmit={submitHandler}>
      {formStatus === "unSent" && <FormContent inputHandler={inputHandler} formData={formData} clearField={clearField}/>}
      {formStatus === "sending" && <SendingAnimation size="5em" color="salmon"/>}
      {formStatus === "sent" && (
        <>
          <H2 style={{margin: "auto", textAlign: "center"}}>Sweet! We'll get back to you shortly.</H2>
          <Btn style={{margin: "30px auto 0px"}} onClick={() => setFormStatus("unSent")}>Done</Btn>
        </>)}
    </StForm>
  )
}

export default Form
